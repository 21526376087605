<template src="./CreateSampleManagementPage.html"></template>
<style scoped lang="scss" src="./CreateSampleManagementPage.scss"></style>

<script>
import {OrderType} from "../../../../../constants";
import { OrderCreateMixin } from '../../../../../mixins'

const OrderCreateForm = () => import('@/components/form/mass-management-form/MassManagementForm')

export default {
  name: 'CreateSampleManagementPage',
  mixins: [OrderCreateMixin],
  components: { OrderCreateForm },
  data: function () {
    return {
      orderType: OrderType.Sample
    }
  },
  methods: {
    handleOnFormCancel() {
      this.$router.push({ name: 'routes.sampleManagement' })
    }
  }
}
</script>
